<template>
  <v-app id="app">
    <template v-if="$route.path !== '/login' && $route.path !== '/urenregistratie'">
      <v-navigation-drawer
        app
        fixed
        clipped
        v-model="drawer"
        class="blue-grey darken-4"
        width="200"
      >
        <div class="pa-2">
          <p>Username</p>
        </div>
        <v-divider></v-divider>
        <v-list>
          <v-list-item		  
            v-for="menu in menus"
            :key="menu.title"
            :href="menu.link"
            link
            class="blue-grey--text text--lighten-4"
            :class="menu.link === $route.path ? 'blue-grey darken-2' : ''"
          >
            <v-list-item-icon>
              <v-icon class="blue-grey--text text--lighten-4">{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block @click="logout">
              Afmelden
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <v-app-bar
        app
        clipped-left
        color="primary"
        dark
      >
        <div class="d-flex align-center">
          <v-img
            src="./assets/flexmanage-logo.png"
            class="shrink mr-2"
            contain
            width="200"
          />
        </div>

        <v-spacer></v-spacer>

        
      </v-app-bar>
    </template>
    <template v-if="$route.path == '/urenregistratie'">
      <v-app-bar
        app
        color="primary"
        dark
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <v-toolbar-title>Title</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
      >
        
      </v-navigation-drawer>
     
    </template>
    <v-main>
      <router-view/>
    </v-main>
    <template v-if="$route.path == '/urenregistratie'">
      <v-bottom-navigation v-model="value">
        <v-btn value="recent">
          <span>Recent</span>

          <v-icon>mdi-history</v-icon>
        </v-btn>

        <v-btn value="favorites">
          <span>Favorites</span>

          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn value="nearby">
          <span>Nearby</span>

          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>
    
  </v-app>
</template>

<script>
import axios from 'axios'
import './assets/custom.css'

export default {
  
  name: 'App',

  data: () => ({
    LoginStatus: '1',
    drawer: true,
    menus: [
      { title: 'Klanten', icon: 'mdi-account-group', link: '/klanten'},
      { title: 'Werknemers', icon: 'mdi-account-hard-hat', link: '/werknemers' },
      { title: 'Opdrachten', icon: 'mdi-briefcase-check', link: '/opdrachten' },
      { title: 'Planning', icon: 'mdi-view-list', link: '/planning' },
    ]
  }),
  methods: {
    logout () {
      axios
        .get(process.env.VUE_APP_API_URL + '/auth/logout')
        .then(response => {
          if(response.data.logout == '1') this.$router.push({ path: '/login' })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
};
</script>
