<template>
  <v-container fluid>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeView',

  }
</script>
