<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <p class="text-h4" align="center">
          FlexManage
        </p>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          class="mx-auto"
          max-width="580"
          elevation="5"
        >
          <v-card-title class="black">
            <p class="text-h5 white--text ml-3">
              Aanmelden
            </p>
            <v-spacer></v-spacer>
            <v-img
              max-height="75"
              max-width="160"
              contain
              src="../assets/login-logo.png"
            ></v-img>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    class="mt-4"
                  >
                    <v-text-field
                      v-model="user_email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="user_password"
                      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="passwordRules"
                      :type="show_password ? 'text' : 'password'"
                      label="Wachtwoord"
                      required
                      @click:append="show_password = !show_password"
                      @keyup.enter="submit"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4 mb-4"
              @click="submit"
            >
              Aanmelden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="test === 'https://api.d-en-e-groep.nl'" align="center" justify="center">
      <span><h2>flexmanage.d-en-e-groep.nl is verplaatst naar <a href="https://d-en-e.flexmanage.nl">d-en-e.flexmanage.nl</a></h2></span>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  
  export default {
    name: 'LoginView',

    data: () => ({
      valid: false,
      user_email: '',
      user_password: '',
      show_password: false,
      emailRules: [
        v => !!v || 'E-mail is verplicht!',
        v => /.+@.+/.test(v) || 'Dit is geen geldig e-mailadres',
      ],
      passwordRules: [
        v => !!v || 'Wachtwoord is verplicht',
      ],
      test: process.env.VUE_APP_API_URL,
    }),
    methods: {
      async submit () {
        await axios
        .post(process.env.VUE_APP_API_URL + '/auth', { user: this.user_email, password: this.user_password })
        .then(response => {
          if(response.data.status == '1') {
            this.$router.push({ path: '/' })
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
  }
</script>