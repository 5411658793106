import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

Vue.prototype.$userData = []

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: guardMyroute,
    component: HomeView
  },
  {
    path: '/klanten',
    name: 'klanten',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/KlantenView.vue')
  },
  {
    path: '/werknemers',
    name: 'werknemers',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WerknemersView.vue')
  },
  {
    path: '/opdrachten',
    name: 'opdrachten',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OpdrachtenView.vue')
  },
  {
    path: '/planning',
    name: 'planning',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlanningView.vue')
  },
  {
    path: '/werknemerstest',
    name: 'werknemerstest',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WerknemersViewTest.vue')
  },
  {
    path: '/werknemerstest2',
    name: 'werknemerstest2',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WerknemersViewTest2.vue')
  },
    {
    path: '/planning_test',
    name: 'planning_test',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlanningView_test.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function guardMyroute(to, from, next)
{
  axios
    .get(process.env.VUE_APP_API_URL + '/auth')
    .then(response => {
      if(response.data.status === '1') {
        next();
        console.log(response.data.status)
      }
      else {
        next('/login');
      }
    })
    .catch(error => {
      console.log(error)
    })
}


export default router
